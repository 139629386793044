import orderItem from "../../components/order-item/index.vue";
import { mapActions } from "vuex";

export default {
  name: "history-orders",
  components: {
    orderItem,
  },
  data() {
    return {
      currentPage: 1,
      list: null,
      hasNextPage: false,
      loading: false,
    };
  },
  created() {
    this.fetchInfo();
  },
  methods: {
    ...mapActions({
      fetchHistoryOrders: `profile/GET_HISTORY_ORDERS`,
    }),
    fetchInfo() {
      this.loading = true;
      if (this.$route.query.order) {
        this.fetchHistoryOrdersUntilFound({
          page: this.currentPage,
          orderId: this.$route.query.order,
          perPage: 15,
        });
      } else {
        this.fetchHistoryOrders({ page: this.currentPage, perPage: 15 }).then(res => {
          this.list = res.data;
          this.hasNextPage = !!res.links.next;
          this.loading = false;
        });
      }
    },
    fetchHistoryOrdersUntilFound({ page, orderId }) {
      return this.fetchHistoryOrders({ page, perPage: 50 }).then(res => {
        this.list = this.list ? [...this.list, ...res.data] : res.data;
        this.hasNextPage = !!res.links.next;
        const orderFound = this.list.some(item => item.id === orderId);

        if (this.hasNextPage && !orderFound) {
          return this.fetchHistoryOrdersUntilFound({ page: page + 1, orderId, perPage: 50 });
        } else {
          this.loading = false;
        }
      });
    },
    loadMore() {
      this.loading = true;
      this.currentPage++;
      this.fetchHistoryOrders({ page: this.currentPage }).then(res => {
        this.list = [...this.list, ...res.data];
        this.hasNextPage = !!res.links.next;
        this.loading = false;
      });
    },
  },
};
